export const roles = [
  'Booking Admin',
  'Client Admin',
  'Medical Admin',
  'Testing Admin',
  'Tester',
  'Super'
]

export const labs = ['DynaLIFE', 'PMO Lab']

const times = [1, 1.5, 2, 2.5, 3, 3.5, 4]
export const durations = times.map(row =>
  row === 1 ? `${row} hour` : `${row} hours`
)

export const statuses = [
  'Requested',
  'Ready',
  'Confirmed',
  'Testing Complete',
  'Results Complete',
  'Closed'
]
