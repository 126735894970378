<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Update Project</h1>
  <div class="p-grid">
    <div class="p-col-12 p-sm-6">
      <ProjectForm @submitted="update" :project="project" :editMode="true" />
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-sm-6">
      <h3>Assign to Project</h3>
      <ProjectAssignUser
        @add="addUsers"
        @remove="removeUser"
        :project="project"
        :users="users"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast.js'
import ProjectForm from '@/components/ProjectForm'
import ProjectAssignUser from '@/components/ProjectAssignUser'
import { db, firestore, FieldValue } from '@/services/firebase'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { ProjectForm, ProjectAssignUser, BaseBreadcrumb },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const project = ref({})
    const users = ref([])
    const breadcrumbs = [
      { label: 'Projects', to: '/projects' },
      { label: 'Update' }
    ]

    function getProject() {
      db.ref('projects/' + route.params.id)
        .once('value')
        .then(snapshot => {
          project.value = snapshot.val()
          project.value.id = route.params.id
        })
    }

    function getUsers() {
      firestore
        .collection('users')
        .where('active', '==', true)
        .where('roles', 'array-contains', 'Client Admin')
        .get()
        .then(querySnapshot => {
          const usersList = []

          querySnapshot.forEach(doc => {
            const user = doc.data()
            user.id = doc.id
            usersList.push(user)
          })
          users.value = usersList.sort((a, b) => a.name.localeCompare(b.name))
        })
    }

    function update(formData) {
      db.ref('projects/' + route.params.id)
        .update({ name: formData.name })
        .then(() => {
          router.push({ name: 'ProjectIndex' })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: err.message,
            life: 3000
          })
        })
    }

    function saveUsers(users, msg) {
      // save users object to project db
      db.ref('projects/' + route.params.id)
        .update({ users })
        .then(() => {
          getProject()
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: msg,
            life: 3000
          })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: err.message,
            life: 3000
          })
        })
    }

    function addUsers(users) {
      // save users object to project db
      saveUsers(users, 'User Successfully Added')

      // add project id to user projects array
      users.forEach(row => {
        firestore
          .collection('users')
          .doc(row.id)
          .update({ projects: FieldValue.arrayUnion(route.params.id) })
          .then(() => {
            getUsers()
            if (store.getters.user.id === row.id) store.dispatch('fetchUser')
          })
      })
    }

    function removeUser(userId) {
      const users = project.value.users.filter(row => {
        if (row.id !== userId) return true
      })
      // save users object to project db
      saveUsers(users, 'User Successfully Removed')

      // remove project id to user projects array
      firestore
        .collection('users')
        .doc(userId)
        .update({ projects: FieldValue.arrayRemove(route.params.id) })
        .then(() => {
          getUsers()
          if (store.getters.user.id === userId) store.dispatch('fetchUser')
        })
    }

    getProject()
    getUsers()

    return { update, project, users, addUsers, removeUser, breadcrumbs }
  }
}
</script>
