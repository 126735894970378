<template>
  <form @submit.prevent="submit" v-if="canUpdateProject">
    <div class="p-mb-3 p-input-filled">
      <div class="p-field">
        <InputText
          placeholder="Project Name"
          type="text"
          class="project-name"
          v-model="formData.name"
          @blur="validation.formData.name.$touch()"
          :class="{ 'p-invalid': validation.formData.name.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.name"
          :rules="['required']"
        />
      </div>
    </div>
    <Button
      type="submit"
      class="submit"
      :disabled="disabled || validation.$invalid"
      :label="label"
    />
  </form>
</template>

<script>
import { ref, watchEffect } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import { roles } from '@/helpers/constants'
import { getPermissions } from '@/helpers/permissions'

export default {
  components: { Button, InputText, BaseValidationErrors },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Save Project'
    },
    project: {
      type: Object,
      default: new Object()
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const formData = ref({
      name: null,
      active: true
    })

    const rules = {
      formData: {
        name: { required }
      }
    }

    const validation = useVuelidate(rules, { formData })

    const { canUpdateProject } = getPermissions()

    watchEffect(() => {
      if (Object.keys(props.project).length) {
        formData.value = props.project
      }
    })

    function submit() {
      validation.value.$touch()
      if (!validation.value.$invalid) emit('submitted', formData.value)
    }

    return { formData, roles, validation, submit, canUpdateProject }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
</style>
