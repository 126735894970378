<template>
  <div v-if="canUpdateProject" class="p-grid p-input-filled">
    <div class="p-col-12 p-sm-6">
      <form @submit.prevent="onSubmit">
        <AutoComplete
          :multiple="true"
          v-model="assigned"
          :suggestions="filteredUsers"
          @complete="searchUsers($event)"
          placeholder="Name"
          field="name"
          :dropdown="true"
          @item-select="onSubmit"
        />
      </form>
      <ul v-if="project.users && project.users.length">
        <li v-for="tester in project.users" :key="tester.id">
          <div class="p-d-flex p-ai-center">
            {{ tester.name }}
            <Button
              v-if="canUpdateProject"
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text p-ml-1"
              @click="removeAssigned(tester.id)"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
  <em v-else-if="!canUpdateProject">No Collectors Assigned</em>
</template>

<script>
import { ref, computed, watchEffect } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import Button from 'primevue/button'
import { getPermissions } from '@/helpers/permissions'

export default {
  components: { AutoComplete, Button },
  props: {
    project: {
      type: Object,
      default: new Object()
    },
    users: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['add', 'remove'],
  setup(props, { emit }) {
    const assigned = ref([])
    const filteredUsers = ref([])
    const { canUpdateProject } = getPermissions()

    const availableUsers = computed(() => {
      let usersUnavailable = []

      if (props.project.users) {
        // check to see which users have already been assigned to this project
        usersUnavailable = props.project.users.map(row => row.id)
      }

      // filter out unavailable users
      return props.users.filter(user => {
        // console.log(user);
        // if (!user.projects.length) return false;
        return !usersUnavailable.includes(user.id)
      })
    })

    function filterUsers() {
      // filter out users that are already assigned to a project
      return [
        ...availableUsers.value.filter(user => {
          return !user.projects.length
        })
      ]
    }

    watchEffect(() => {
      filteredUsers.value = filterUsers()
    })

    async function searchUsers(event) {
      if (!event.query.trim().length) {
        filteredUsers.value = filterUsers()
      } else {
        filteredUsers.value = filterUsers().filter(user => {
          return user.email.toLowerCase().startsWith(event.query.toLowerCase())
        })
      }
    }

    function removeAssigned(userId) {
      filteredUsers.value = filterUsers()
      emit('remove', userId)
    }

    function onSubmit() {
      let users = []
      if (props.project.users) users = props.project.users
      assigned.value.forEach(row => {
        users.push(row)
      })
      emit('add', users)
      assigned.value = [] // clear text field
    }

    return {
      assigned,
      filteredUsers,
      searchUsers,
      removeAssigned,
      onSubmit,
      canUpdateProject
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
</style>
